<template>
  <div>
    <NavBar />
    <div class="Container">
      <h2>Изменение остатков</h2>
      <v-autocomplete label="Выберите кафе" outlined v-model="cafe" v-on:change="chooseCafe" :items="getCafes()"></v-autocomplete>
      <div v-if="this.cafe.length > 0">
        <!-- <h2 class="mx-5">Кафе</h2> -->
        <v-card-title class="d-flex justify-center"></v-card-title>
        <v-data-table :headers="headers" :items="products" :loading="isMenuItemsForAmountLoading" disable-pagination hide-default-footer>
          <template v-slot:item.actions="{ item }">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field hide-spin-buttons type="number" v-model.number="item.currentAmount"></v-text-field>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-btn @click="editAmountLeftClick" color="#e21a1a" class="white--text">Сохранить</v-btn>
        </v-card-actions>
      </div>
    </div>
    <div class="notification">
      <Notification />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import NavBar from '@/components/NavBar';
import Notification from '@/components/Notification';

export default {
  data() {
    return {
      showEditedDialog: {},
      editedIndex: -1,
      editedAmount: null,
      cafe: '',
      amountField: [(v) => (v > 0 && v < 4) || 'Некорректные данные']
    };
  },
  components: {
    NavBar,
    Notification
  },
  computed: {
    headers() {
      return [
        {
          text: 'Название',
          value: 'nameRu'
        },
        {
          text: 'Остаток',
          value: 'amountLeft'
        },
        {
          text: 'Изменить на',
          value: 'actions'
        }
      ];
    },
    products() {
      let productsArr = [];
      if (this.menuItemsForAmount !== undefined && this.menuItemsForAmount !== null) {
        let currMenu = this.menuItemsForAmount.find((currCafe) => currCafe.token === this.cafe);
        currMenu.items.forEach((item) => {
          item.currentAmount = item.amountLeft;
          productsArr.push(item);
        });
      }
      return JSON.parse(JSON.stringify(productsArr));
    },
    cafes() {
      let cafesArr = [];
      if (this.menuItemsForAmount !== undefined && this.menuItemsForAmount !== null) {
        this.menuItemsForAmount.forEach((item) => {
          cafesArr.push(item);
        });
      }
      return JSON.parse(JSON.stringify(cafesArr));
    },
    ...mapGetters('menu', ['menuItemsForAmount']),
    ...mapGetters('menu', ['isMenuItemsForAmountLoading']),
    ...mapGetters('menu', ['menuItemsForPutAmountErrorMessage'])
  },
  methods: {
    getMenuList() {
      this.getMenuItemsForAmount();
    },
    chooseCafe() {
      this.products = JSON.parse(JSON.stringify(this.cafes.find((menu) => this.cafe === menu.token)));
    },
    editAmountLeftClick() {
      let obj = {
        cafeToken: this.cafe,
        items: []
      };
      this.products.forEach((item) => {
        if (item.amountLeft !== item.currentAmount)
          obj.items.push({
            itemToken: item.token,
            amountLeft: item.currentAmount
          });
      });
      if (obj.items.length > 0) {
        this.putMenuItemsForAmount(obj)
          .then(() => {
            this.getMenuList();
            this.showNotification('Остатки успешно изменены', 'success', '2000', 'mdi-check');
          })
          .catch(() => {
            this.showNotification(`Возникла ошибка: ${this.menuItemsForPutAmountErrorMessage}`, 'red', '2000', 'mdi-alert-circle-outline');
          });
      } else this.showNotification(`Ничего не изменилось`, 'red', '2000', 'mdi-alert-circle-outline');
    },
    getCafes() {
      let list = [];
      if (this.cafes !== undefined && this.cafes !== null && this.cafes.length >= 0) {
        for (let i = 0; i < this.cafes.length; i++) {
          list.push({ text: this.cafes[i].nameRu, value: this.cafes[i].token });
        }
      }
      return list;
    },
    showNotification(message, color, timer, icon) {
      this.showSnack({
        message,
        color,
        timer,
        icon
      });
    },
    ...mapActions('menu', ['getMenuItemsForAmount']),
    ...mapActions('menu', ['putMenuItemsForAmount']),
    ...mapActions('notification', ['showSnack'])
  },
  mounted() {
    this.getMenuList();
  }
};
</script>
<style scoped>
/* .Container {
  margin-top: 10%;
} */
.notification {
  position: fixed;
  bottom: 5px;
  right: 5px;
}
</style>
