<template>
  <div class="snackbar">
    <v-snackbar :color="color" :timeout="timer" v-model="showSnackbar" absolute bottom outlined right>
      <v-icon left>{{ icon }}</v-icon
      >{{ message }}
    </v-snackbar>
  </div>
</template>
<script>
export default {
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'notification/SHOW_MESSAGE') {
        this.message = state.notification.message;
        this.color = state.notification.color;
        this.timer = state.notification.timer;
        this.icon = state.notification.icon;
        this.showSnackbar = true;
      }
    });
  },
  data() {
    return {
      showSnackbar: false,
      message: '',
      color: 'success',
      icon: '',
      timer: 3000
    };
  }
};
</script>
