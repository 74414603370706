<template>
  <nav>
    <v-navigation-drawer app permanent mobile-breakpoint="0" width="90px">
      <v-list dense class="d-flex flex-column py-0">
        <template v-for="group in userLinkGroups">
          <v-list-item v-for="link in group" :key="link.label" class="px-1" color="#e21a1a" two-line router :to="link.url">
            <v-list-item-content>
              <v-list-item-icon class="justify-center">
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle class="text-caption text-center">
                {{ link.label }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="white" flat app>
      <img class="mr-3" src="../assets/RZD.svg" />
      <v-toolbar-title class="ml-3">
        <div class="Header">Дирекция железнодорожных вокзалов —</div>
        <div class="text-none mr-3 font-family-RussianRail">филиал ОАО «РЖД»</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="grey--text" text v-bind="attrs" v-on="on">
            <v-icon left>mdi-account</v-icon>
            <span class="font-weight-light">{{ user.username }}</span>
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-divider class="mx-2"></v-divider>
          <v-list-item @click="logoutUser">
            <v-list-item-content>
              <v-list-item-title>Выход</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import lodash from 'lodash';
export default {
  data() {
    return {
      drawer: false,
      defaultLink: [
        {
          label: 'Бистро РЖД',
          icon: 'mdi-folder-outline',
          url: '/'
        }
      ],
      courierLink: [
        {
          label: 'Курьер',
          icon: 'mdi-account-details-outline',
          url: '/courier'
        }
      ],
      kitchenLink: [
        {
          label: 'Кухня',
          icon: 'mdi-silverware-fork-knife',
          url: '/kitchen'
        }
      ],
      operatorLink: [
        {
          label: 'Заказы',
          icon: 'mdi-folder-outline',
          url: '/operator'
        }
      ],
      controlLink: [
        {
          label: 'Рестораны',
          icon: 'mdi-food-outline',
          url: '/control'
        }
      ],
      amountLink: [
        {
          label: 'Остатки',
          icon: 'mdi-plus-minus-variant',
          url: '/amount'
        }
      ],
      stationLink: [{ label: 'Станции', icon: 'mdi-train', url: '/stations' }],
      takeoutLink: [
        {
          label: 'Точки выдачи',
          icon: 'mdi-chart-bar',
          url: '/takeouts'
        }
      ],
      qrLink: [
        {
          label: 'QR',
          icon: 'mdi-qrcode-edit',
          url: '/qr'
        }
      ],
      // reportLink: [
      //   {
      //     label: 'Отчеты',
      //     icon: 'mdi-chart-bar'
      //     // url: '/reports'
      //   }
      // ],
      userLink: [
        {
          label: 'Пользователи',
          icon: 'mdi-account-details-outline',
          url: '/users'
        }
      ]
    };
  },
  computed: {
    userLinkGroups() {
      const userGroups = [];
      userGroups.push(this.defaultLink);
      // TODO new permission names
      if (this.checkPermission('super-admin')) {
        userGroups.push(
          this.courierLink,
          this.kitchenLink,
          this.operatorLink,
          this.controlLink,
          this.amountLink,
          this.stationLink,
          this.takeoutLink,
          this.qrLink,
          // this.reportLink,
          this.userLink
        );
      }
      if (this.checkPermission('direction-admin')) {
        userGroups.push(this.stationLink, this.userLink);
      }
      if (this.checkPermission('station-admin')) {
        userGroups.push(this.operatorLink, this.stationLink, this.qrLink, this.userLink);
      }
      if (this.checkPermission('station-courier')) {
        userGroups.push(this.courierLink);
      }
      if (this.checkPermission('cafe-admin')) {
        userGroups.push(this.controlLink, this.amountLink, this.qrLink, this.userLink);
      }
      if (this.checkPermission('cafe-mngr') || this.checkPermission('cafe-co-mngr')) {
        userGroups.push(this.controlLink);
      }
      if (this.checkPermission('cafe-kitchen')) {
        userGroups.push(this.kitchenLink, this.amountLink);
      }
      return lodash.uniq(userGroups);
    },
    ...mapGetters('auth', ['user'])
  },
  methods: {
    checkPermission(permissionKey) {
      // TODO if permission logic changes
      if (this.user && this.user.permissions && this.user.permissions[permissionKey] && this.user.permissions[permissionKey] > 0) {
        return true;
      }
      return false;
    },
    logoutUser() {
      this.logout();
      this.$router.push('/login');
    },
    ...mapActions({ logout: 'auth/logout' })
  }
};
</script>
<style scoped>
.Header {
  font-family: RussianRail;
}
</style>
